module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PVLVFZR","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Moms on Maternity","short_name":"m-o·m","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"./src/images/MOM_FullLogo-black-square.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"52f07ad86149b5361e0a6a8d70941c6e"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/codebuild/output/src350056408/src/git-codecommit.us-west-2.amazonaws.com/v1/repos/mom/src/components/layout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1200,"showCaptions":true,"linkImagesToOriginal":true}}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/codebuild/output/src350056408/src/git-codecommit.us-west-2.amazonaws.com/v1/repos/mom"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"EAAGZAYxLJXmQBAPR7j8ZBWOdKQzc5k7lBjor5UwzXTu7u4CyD93ETYCScUKfiNjoPFUlJ9CYxoht71bxiBfdwmkZBn8tUpD6EhZCpDW7D7rtdV6udnjV8gT2EJ03ksMQ3ZAhkjWSMtJs7ZBNFLvvfXu3nxoro5uhScsZBBWFYoVvG6QzxUNgmy4SwCj077N1awZD"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
